import React from 'react'
import Logo from '../assets/logo/logo.png'
export default function Navbar() {
    return (
        <div className='w-full bg-white/90 h-16 z-50 fixed top-0 border-b-2'>
            <div className='w-full h-full flex justify-between items-center container mx-auto'>
                <div className='logo h-full'>
                    <a href='/'><img className='h-full w-auto object-cover' src={Logo} alt='ttstack logo' /></a>
                </div>
                {/* menu */}
                <div className='menu h-full flex items-center gap-3'>
                    <a className='btn bg-transparent h-full w-auto hover:bg-indigo/80 duration-100 hover:text-white flex items-center p-1 justify-center text-center text-[8px] md:text-[12px] lg:text-[17px]' href='#solutions'>Solutions</a>
                    <a className='btn bg-transparent h-full w-auto hover:bg-indigo/80 duration-100 hover:text-white flex items-center p-1 justify-center text-center text-[8px] md:text-[12px] lg:text-[17px]' href='#about'>About</a>
                    <a className='btn bg-transparent h-full w-auto hover:bg-indigo/80 duration-100 hover:text-white flex items-center p-1 justify-center text-center text-[8px] md:text-[12px] lg:text-[17px]' href='https://tunahantatli.netlify.app' target='blank'>Portfolio</a>
                    <a className='btn bg-transparent h-full w-auto hover:bg-indigo/80 duration-100 hover:text-white flex items-center p-1 justify-center text-center text-[8px] md:text-[12px] lg:text-[17px]' href='#contact'>Contact</a>
                </div>
            </div>
        </div>
    )
}
